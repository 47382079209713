<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { http } from '@/http'
import { regFenToYuan} from '@/utils'

export default {
  setup() {
    let drawalAmount = reactive({})
    let route = useRoute()
    function withdrawalDetail() {
      http.post('/?cmd=com.xqxc.api.pigear.pay.BrokerWithdrawalService.withdrawalDetail', {
        id: route.query.id
      }).then(res => {
        if(res.result) {
          for (let item in res.result) {
            drawalAmount[item] = res.result[item]
          }
        }
      })

    }
    onMounted(withdrawalDetail)  

    return {
      drawalAmount,
      regFenToYuan
    }
  }
}
</script>
<template>
  <div class="page">
    <!--     <div class="page-title">
      <h3>详情</h3>
    </div> -->
    <div class="r-box" style="margin-top:.3rem">
      <span class="amount">{{regFenToYuan(drawalAmount.arrivalAmount)}}</span>
      <p class="bank">收益支取-到{{drawalAmount.bankName}}（{{drawalAmount.bankNo}}）</p>
      <div class="d-item">
        <p><span>当前状态：</span>{{drawalAmount.status}}</p>
        <p><span>提现金额：</span>{{regFenToYuan(drawalAmount.withdrawalAmount)}}</p>
        <p><span>申请时间：</span>{{drawalAmount.gmtCreate}}</p>
        <p><span>提现单号：</span>{{drawalAmount.id}}</p>
        <p><span>手续费用：</span>{{regFenToYuan(drawalAmount.serviceCharge)}}</p>
        <p><span>税率费用：</span>{{regFenToYuan(drawalAmount.taxCharge)}}</p>
        <p><span>驳回原因：</span><span class="error">{{drawalAmount.remark}}</span></p>
      </div>
    </div>
  </div>
</template>
<style scoped>
    .amount { display: block; text-align: center; font-size:.5rem }
    .bank { color: #666; margin: .3rem 0; text-align: center; font-size:.24rem }
    .d-item { padding: .3rem 0; border-top: 1px solid #eee; font-size:.28rem }
    .d-item p { margin-top:.4rem }
    .d-item span { color:#666 }
    .d-item .error { color: #e61f3e }
</style>
